<script>
import Multiselect from 'vue-multiselect'
import { http } from "@/helpers/http";
import { alert } from '@/helpers/alert'

export default {
	name: 'TimeZoneSettings',
	props: {
		brand: {type: Object, required: true},
	},
	components: {
		Multiselect
	},
	watch: {
		brand: function () {
			this.getTimeZone(this.brand.id)
		}
	},

	data() {
		return {
			selectedZone: {},
			allZones: [],
		};
	},
	created() {
		this.getTimeZone(this.brand.id)
		this.getAllTimeZones();
	},
	methods: {

		async getTimeZone(id) {
			await http.get(`brand/timezone/${id}`)
				.then((response) => response.json())
				.then((response) => {
					this.selectedZone = response
				})
		},

		async getAllTimeZones() {
			await http.get('brand/timezone/all')
				.then((response) => response.json())
				.then((response) => {
					this.allZones = response
				})
		},

		saveChanges() {
			http.post('brand/timezone/update',
				{
					json: {
						zoneId: this.selectedZone.zoneId,
						brandId: this.brand.id
					}
				})
			alert.success("Timezone successfully updated")
		},
	}
}
</script>

<template>
	<div class="row">
		<b-tab title="Time Zone" style="width: 100%">
					<label>Time zone </label>
					<multiselect
						v-model="selectedZone"
						label="name"
						track-by="zoneId"
						:options="allZones"
						:allow-empty="false"
						:max-height="500"
					>
					</multiselect>
			<br>
				<div class="float-right">
					<b-button variant="primary" class="btn-label" @click="saveChanges">
						<i class="bx bx-save label-icon"></i> Save changes
					</b-button>
				</div>
		</b-tab>
	</div>
</template>
