<script>
import Multiselect from 'vue-multiselect'
import { http } from "@/helpers/http";
import { alert } from '@/helpers/alert'
import {userService} from "@/helpers/authservice/user.service";

export default {
	name: 'TicketSettings',
	props: {
		brand: { type: Object, required: true },
	},
	components: {
		Multiselect
	},
	watch: {
		brand: function () {
			this.getBrandDetails(this.brand.id)
		}
	},

	data() {
		return {
			name: null,
			ticketCloseTimeMin: 0
		};
	},
	created() {
		this.getBrandDetails(this.brand.id)
	},
	methods: {
		async getBrandDetails(id) {
			await http.get(`brand/details/${id}`)
				.then((response) => response.json())
				.then((response) => {
					this.ticketCloseTimeMin = response.ticketCloseTimeMin
					this.name = response.name
				})
		},
		saveChanges() {
			http.post('brand/details/update', {
					json: {
						id: this.brand.id,
						name: this.name,
						ticketCloseTimeMin: this.ticketCloseTimeMin
					}
				}
				)
				.then((response) => {
					alert.success(alert.TEXT_SAVE_SUCCESS)
					userService.getBrandList();
				})
				.catch((error) => {
					alert.error(alert.TEXT_SAVE_FAILED)
				})
		},
	}
}
</script>

<template>
	<div class="row">
		<b-tab title="Ticket" style="width: 100%">
			<b-form-group label="Ticket close time (min)">
				<b-form-input type="number" v-model="ticketCloseTimeMin"></b-form-input>
			</b-form-group>
			<br>
				<div class="float-right">
					<b-button variant="primary" class="btn-label" @click="saveChanges">
						<i class="bx bx-save label-icon"></i> Save changes
					</b-button>
				</div>
		</b-tab>
	</div>
</template>
