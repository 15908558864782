<script>
import { required, email } from 'vuelidate/lib/validators';
import { config } from '@/helpers/config'
import { alert } from '@/helpers/alert'
import Multiselect from 'vue-multiselect'
import Channel from '@/components/channel'

export default {
	name: 'AddBrandModal',
	props: {
		updateData: { type: Function },
	},
	components: { Channel, Multiselect },
	data() {
		return {
			form: {  },
			brandName: '',
			dirty: false,
		};
	},
	validations: {
		form: {
			brandName: { required }
		}
	},
	computed: {
		isNew() {
			return !this.form?.id
		}
	},
	watch: {
		data(data) {
			this.form = { ...data };
		}
	},
	methods: {
		async submit() {
			this.$v.$touch();
			this.updateData(this.form)
		},
		handleOk(bvModalEvt) {
			this.submit()
		},
		resetModal() {
			this.$v.$reset()
			this.dirty = false
			this.form = { ...this.data };

		},
		someThingChanged() {
			this.dirty = true
		}
	}
}
</script>

<template>
	<b-modal id="addBrandModal" size="lg" title="" hide-header @ok="handleOk" @show="resetModal">
		<b-form @submit.prevent="submit" @change="someThingChanged()">
			<div class="row my-1">
				<div class="col-md-12">
					<b-form-group label="Brand name">
						<b-form-input type="string" v-model="form.brandName"></b-form-input>
					</b-form-group>
				</div>
			</div>
		</b-form>
	</b-modal>
</template>
