import { http } from '@/helpers/http.js'
import { config } from '@/helpers/config'

export const userService = {
    login,
    logout,
    register,
	getBrandList,
	getTicketCategoryList
};

function login(email, password) {

	// Make sure local storage is cleared
	localStorage.clear()

	return http.post('login', {
		json: {
			email: email,
			password: password
		},
	})
		.then((response) => response.json())
		.then((response) => {
			localStorage.setItem('user', JSON.stringify(response))
			getBrandList()
			getChannelList()
			getAgentList()
			getTicketCategoryList()
			getCategoryStatus()
			return response
		})
		.catch((error) => {
			//showHttpError(error)
			//console.error(response)
		})
}

function getBrandList() {
	http.get('brand/list')
		.then((response) => response.json())
		.then((response) => {
			localStorage.setItem(config.storage.BRANDS, JSON.stringify(response))
		})
}

function getChannelList() {
	http.get('channel/list')
		.then((response) => response.json())
		.then((response) => {
			localStorage.setItem(config.storage.CHANNELS, JSON.stringify(response))
		})
}

function getAgentList() {
	http.get('settings/agents/list')
		.then((response) => response.json())
		.then((response) => {
			localStorage.setItem(config.storage.AGENTS, JSON.stringify(response))
		})
}

async function getTicketCategoryList() {
	const response = await http.get('settings/category/active').json()
	localStorage.setItem(config.storage.CATEGORIES, JSON.stringify(response))
}

async function getCategoryStatus() {
	try {
		const response = await http.get('settings/category/enabled').json()
		localStorage.setItem(config.storage.CATEGORIES_ENABLED, response)
	} catch (e) {
	}
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
				location.reload(true);
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
