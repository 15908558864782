<script>
import Multiselect from 'vue-multiselect'
import { http } from "@/helpers/http";
import { alert } from '@/helpers/alert'
import { userService } from "@/helpers/authservice/user.service";
import Switches from 'vue-switches'

export default {
	name: 'TicketSettings',
	props: {
		brand: { type: Object, required: true },
	},
	components: {
		Multiselect,
		Switches
	},
	watch: {
		brand: function () {
			this.getBrandDetails(this.brand.id)
		}
	},

	data() {
		return {
			name: null,
			enabled: false,
			ticketCloseTimeMin: 0
		};
	},
	created() {
		this.getBrandDetails(this.brand.id)
	},
	methods: {
		async getBrandDetails(id) {
			await http.get(`brand/details/${id}`)
				.then((response) => response.json())
				.then((response) => {
					this.ticketCloseTimeMin = response.ticketCloseTimeMin
					this.name = response.name
					this.enabled = response.enabled
				})
		},
		async changeStatus(enabled) {
			try {
				let response = await http.post('brand/enable', {
					json: {
						id: this.brand.id,
						enabled: enabled
					}
				}).json()
				this.enabled = response
			} catch (e) {}
		},
		saveChanges() {
			http.post('brand/details/update', {
					json: {
						id: this.brand.id,
						name: this.name,
						ticketCloseTimeMin: this.ticketCloseTimeMin
					}
				}
				)
				.then((response) => {
					alert.success(alert.TEXT_SAVE_SUCCESS)
					userService.getBrandList();
				})
				.catch((error) => {
					alert.error(alert.TEXT_SAVE_FAILED)
				})
		},
	}
}
</script>

<template>
	<div class="row">
		<b-tab title="Main" style="width: 100%" active>
			<label>Enable/disable brand </label>
			<br>
			<switches
				v-model="enabled"
				type-bold="true"
				:color="enabled ? 'success': 'default'"
				:emit-on-mount="false"
				class="mb-0 mt-2"
				@input="changeStatus">
			</switches>
			<b-form-group label="Brand name">
				<b-form-input type="string" v-model="name"></b-form-input>
			</b-form-group>
			<br>
				<div class="float-right">
					<b-button variant="primary" class="btn-label" @click="saveChanges">
						<i class="bx bx-save label-icon"></i> Save changes
					</b-button>
				</div>
		</b-tab>
	</div>
</template>
