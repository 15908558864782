<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect'
import AddBrandModal from './add-brand-modal'
import { http } from "@/helpers/http";
import { config } from "@/helpers/config";
import TimeZoneSettings from "./tabs/time-zone";
import MainSettings from "./tabs/main";
import TicketSettings from "./tabs/ticket";
import { alert } from "@/helpers/alert";
import { userService } from "@/helpers/authservice/user.service";

/**
 * Buy/Sell component
 */
export default {
	page: {
		title: "General settings",
		meta: [{ name: "description", content: appConfig.description }]
	},
	components: {TicketSettings, MainSettings, TimeZoneSettings, PageHeader, Multiselect, AddBrandModal },
	data() {
		return {
			selectedBrand: null,
			allBrands: JSON.parse(localStorage.getItem(config.storage.BRANDS)),
			title: "General settings",
			modalName: 'addBrandModal',
			automationModalData: {},
		}
	},
	created() {
		this.selectedBrand = this.allBrands[0]
		this.getBrandList();
	},

	methods: {
		getBrandList() {
			http.get('brand/list/all')
				.then((response) => response.json())
				.then((response) => {
					this.allBrands = response
				})
		},
		getSearchClass() {
			return this.loading ? 'bx-loader bx-spin' : 'bxs-plus-circle'
		},
		updateData(data) {
			http.post('brand/add', {
					json: {
						value: data.brandName
					}
				})
				.then((response) => {
					alert.success(alert.TEXT_ADD_SUCCESS)
					userService.getBrandList();
				})
				.catch((error) => {
					alert.error(alert.TEXT_ADD_FAILED)
				})
		},
		showModal() {
			this.$bvModal.show(this.modalName)
		},
	}
};
</script>

<template>
	<div>
		<PageHeader :title="title" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<div class="card">
									<div class="card-body">
										<h4 class="card-title">General settings</h4>
										<p class="card-title-desc">A mobile and touch friendly input spinner component for Bootstrap</p>
										<multiselect
											v-model="selectedBrand"
											label="name"
											track-by="id"
											:options="allBrands"
											:allow-empty="false"
										>
										</multiselect>
										<b-button class="m-2 float-right" variant="success" @click="showModal">
											<i :class="`bx ${this.getSearchClass()} font-size-16 align-middle mr-2`"></i> Add new brand
										</b-button>
									</div>
								</div>
							</div>
						</div>
						<b-tabs content-class="crypto-buy-sell-nav-content p-4" nav-class="nav-tabs-custom">
							<MainSettings :brand="selectedBrand"></MainSettings>
							<TicketSettings :brand="selectedBrand"></TicketSettings>
							<time-zone-settings :brand="selectedBrand"></time-zone-settings>
						</b-tabs>
					</div>
				</div>
			</div>
		</div>
		<AddBrandModal :updateData="updateData" />
	</div>
</template>
